import React from 'react';
import { Routes, Route } from "react-router-dom";
import Index from './pages';

function App() {
  return (
    <Routes>
      <Route index element={<Index />} />
      <Route path={'/:id'} element={<Index />} />
      <Route path={'/:id/:phone'} element={<Index />} />
    </Routes>
  );
}

export default App;
