import { useState, useEffect, SyntheticEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../components/layout/wrapper";
import { BsInstagram } from 'react-icons/bs';
import { SlSocialFacebook } from 'react-icons/sl'
import { AiOutlineYoutube, AiOutlineInstagram, AiFillFacebook, AiOutlineLinkedin, AiOutlineTwitter } from 'react-icons/ai'
import { FiInstagram, FiFacebook, FiTwitter, FiLinkedin, FiYoutube } from 'react-icons/fi'
import { FcGoogle, FcSurvey } from 'react-icons/fc'
import CountUp from 'react-countup';
import { Button, Modal, Checkbox, Rate, Input, Select, message } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PatternFormat } from 'react-number-format';

import bcrypt from 'bcryptjs';

import axios from 'axios';
import { TransactionType } from "../models/attribute/question/question";
import { Question } from "../models/attribute/transactionType/transaction_type";
import { ScoreName } from "../models/attribute/scoreName/score_name";
import { PhoneCode } from "../models/location/phoneCode/phoneCode";
import { User } from "../models/general/user/user";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const { TextArea } = Input;

const { Option } = Select;

interface QuestionInterface {
    name: string;
}
interface RouteParams {
    id?: string;
}

const Index = () => {
    const { id } = useParams<number | any | null>();
    const [ids, setIds] = useState<number>(0);


    useEffect(() => {
        const parsedId = parseInt(id || "", 10);

        // "parsedId" geçerli bir sayıya çevrilebilirse ve 0'dan büyükse state'i güncelliyoruz.
        if (!isNaN(parsedId) && parsedId > 0) {
            setIds(parsedId);
        } else {
            // Geçersiz "id" değeri varsa, state'i null yapabiliriz veya hata mesajı gösterebiliriz.
            setIds(0);
            // Veya hata mesajı göstermek için bir durum oluşturup uygun şekilde kullanabilirsiniz.
        }
    }, [id]);

    const navigate = useNavigate();

    interface Option {
        label: string;
        value: number | string;
    }

    const [messageApi, contextHolder] = message.useMessage();

    //Get 
    const [transaction_types, setTransactionTypes] = useState<Option[]>();
    const [questions, setQuestions] = useState<Question[]>();
    const [score_names, setScoreNames] = useState<any>();

    const [phone_codes, setPhoneCodes] = useState([]);
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('transaction-types');
                const options = data?.map((item: any) => ({
                    label: item.name,
                    value: item.id.toString(),
                }));
                setTransactionTypes(options);

            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`questions`);
                setQuestions(data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`score-names`);
                const updatedDesc = data.map((item: ScoreName) => item.name);

                setScoreNames(updatedDesc);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`phone-codes`);
                setPhoneCodes(data);
            }
        )()
    }, []);

    const [userLoading, setUserLoading] = useState(false);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`agents`);
                setUsers(data);
                setUserLoading(true);
            }
        )()
    }, [isModalOpen, ids]);

    const [agent_status, setAgentStatus] = useState(false);
    const [user_full_name, setUserFullName] = useState('');
    useEffect(() => {
        if (ids) {
            setAgentStatus(true);
        }
        if (ids && userLoading) {
            if (!users.some((user: User) => user.id === ids)) {
                setAgentStatus(false);
                navigate("/");
            } else {
                setAgentStatus(true);
                const matchingUser: any = users.find((user: User) => user.id === ids);
                if (matchingUser) {
                    const userFirstName = matchingUser.first_name;
                    setUserFullName(`${matchingUser.first_name} ${matchingUser.last_name}`);
                }

            }
        } else {
            if (userLoading) {
                navigate("/");
            }
        }
    }, [ids, users, navigate, userLoading]);


    //Modal
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [KVKKModalOpen, setKVKKModalOpen] = useState(false);

    //checkbox
    const [checked_transaction_types, setCheckedTransactionTypes] = useState<any | null>([]);
    const onChange = (checkedValues: CheckboxValueType[]) => {
        setCheckedTransactionTypes(checkedValues);
    };

    const onNotificationChange = (e: CheckboxChangeEvent) => {
        if(e.target.checked){
            setNotificationPermission(1);
        }else{
            setNotificationPermission(0);
        }
    };

    const onPermissionChange = (e: CheckboxChangeEvent) => {
          if(e.target.checked){
            setKVKKPermission(1);
        }else{
            setKVKKPermission(0);
        }
    };


    //Rating
    const questionVal: QuestionInterface[] = [
    ];

    // Initialize a state to hold the user's responses for each question
    const [questionStates, setQuestionStates] = useState<{ [key: number]: number | any }>({});

    // Function to update user response for a specific question
    const setQuestionValue = (questionId: number, value: number | null) => {
        setQuestionStates((prevResponses) => ({
            ...prevResponses,
            [questionId]: value,
        }));
    };

    //Inputs
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [phone_code_id, setPhoneCodeId] = useState(34);
    const [phone, setPhone] = useState('');
    const [agent_id, setAgentId] = useState(id);
    const [notification_permission, setNotificationPermission] = useState(0);
    const [kvkk_permission, setKVKKPermission] = useState(0);

    //Post
    const submit = async (e: SyntheticEvent) => {
        setConfirmLoading(true);
        e.preventDefault();
        let hasError = false;

        if (first_name === '' || first_name === null) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Ad kısmı zorunludur.. Lütfen doldurunuz.',
            });
        }
        if (last_name === '' || last_name === null) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Soyad kısmı zorunludur.. Lütfen doldurunuz.',
            });
        }

        if (phone === '' || phone === null) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Telefon numarası girilmesi zorunludur.. Lütfen doldurunuz.',
            });
        }

        if (comment === '' || comment === null) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Mesaj girilmesi zorunludur.. Lütfen doldurunuz.',
            });
        }

        if (parseInt(agent_id ?? "0") === 0) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Danışman seçimi zorunludur.. Lütfen seçiniz.',
            });
        }

        if (Object.keys(questionStates).length < 5) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'Tüm soruların cevaplanması zorunludur.. Lütfen seçiniz.',
            });
        }

        if (Object.keys(checked_transaction_types).length == 0) {
            hasError = true;
            messageApi.open({
                type: 'error',
                content: 'En az bir işlem türü seçilmesi zorunludur.. Lütfen seçiniz.',
            });

        }
        if (hasError) {
            setConfirmLoading(false);
            messageApi.open({
                type: 'error',
                content: 'Lütfen zorunlu alanları doldurun!',
            });
            return;
        } else {
            setConfirmLoading(true);
            const res = await axios.post('satistification-survey-form', {
                first_name,
                last_name,
                email,
                phone_code_id,
                phone,
                comment,
                checked_transaction_types,
                questionStates,
                notification_permission,
                kvkk_permission,
                agent_id,
            }).then(response => {
                setConfirmLoading(false);
                setIsModalOpen(false);
                messageApi.open({
                    type: 'success',
                    content: 'Mesajınız başarıyla gönderilmiştir. Teşekkür ederiz.',
                });
            })
                .catch(error => {
                    messageApi.open({
                        type: 'error',
                        content: 'Lütfen zorunlu alanları doldurun!',
                    });

                    if (first_name === '' || first_name === null) {
                        messageApi.open({
                            type: 'error',
                            content: 'Ad kısmı zorunludur.. Lütfen doldurunuz.',
                        });
                    }
                    if (last_name === '' || last_name === null) {
                        messageApi.open({
                            type: 'error',
                            content: 'Soyad kısmı zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    if (comment === '' || comment === null) {
                        messageApi.open({
                            type: 'error',
                            content: 'Mesaj kısmı zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    if (phone === '' || phone === null) {
                        messageApi.open({
                            type: 'error',
                            content: 'Telefon numarası girilmesi zorunludur. Lütfen doldurunuz.',
                        });
                    }
                });
        }
        setConfirmLoading(false);
    }
    return (
        <Wrapper>
            <section className="about-area pt-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="about-details aos-init aos-animate mb-4 mt-3" data-aos="zoom-in">
                                <div className="about-details-inner shadow-box pt-4">
                                    <p className="mb-2">
                                        Sevgili Müşterimiz,
                                    </p>
                                    <p className="mb-2">
                                        Siz değerli müşterilerimizin fikirleri bizim için çok önemli! Lütfen {agent_status ? (
                                            <span>
                                                gayrimenkul danışmanımız <strong dangerouslySetInnerHTML={{ __html: user_full_name }}></strong>
                                            </span>
                                        ) : null} ile yaşadığınız hizmet deneyiminizi paylaşarak bize katkıda bulunun ve hizmetlerimizi sizin istekleriniz doğrultusunda daha da geliştirelim.
                                    </p>
                                    <p>
                                        Teşekkür ederiz!
                                    </p>
                                    <div className={agent_status ? 'd-block mt-2' : 'd-none'}>
                                        <Button type="primary" onClick={showModal}>Yorum yapmak için tıklayınız</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4" style={agent_status ? { display: 'none' } : {}}>
                            <div data-aos="zoom-in" className="aos-init aos-animate">
                                <div className="about-blog-box info-box shadow-box h-full">
                                    <a href={'https://g.page/r/ChIJVZxG0hHPc0ARiFhRzD4HBb0/review'} target={'_blank'} rel={'nofollow'} className="overlay-link"></a>
                                    <FcGoogle style={{ height: '120px', width: '120px' }} />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h1>Google haritalar ile yorumlayın</h1>
                                            <p>Yorum yapmak için lütfen buraya dokunun.</p>
                                        </div>
                                        <a href={'https://g.page/r/ChIJVZxG0hHPc0ARiFhRzD4HBb0/review'} target={'_blank'} rel={'nofollow'} className="about-btn">
                                            <img src="/assets/images/icon-2.svg" alt="Button" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={agent_status ? 'col-12 col-sm-12' : 'col-6 col-sm-4'} >
                            <div data-aos="zoom-in" className="aos-init aos-animate form-box">
                                <div className="about-blog-box info-box shadow-box h-full">
                                    <a href="javascript:;" onClick={showModal} className="overlay-link"></a>
                                    <FcSurvey style={{ height: '120px', width: '120px' }} />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h1>Hızlı Form doldurarak yorumlayın</h1>
                                            <p>Yorum yapmak için lütfen buraya dokunun.</p>
                                        </div>
                                        <a href="javascript:;" onClick={showModal} className="about-btn">
                                            <img src="/assets/images/icon-2.svg" alt="Button" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 mt-4 mt-md-0" style={agent_status ? { display: 'none' } : {}}>
                            <div data-aos="zoom-in" className="aos-init aos-animate">
                                <div className="about-blog-box info-box shadow-box h-full">
                                    <a href="https://remax.com.tr/ofis/detay/elite" target={'_blank'} rel={'nofollow'} className="overlay-link"></a>
                                    <img style={{ height: '120px', width: '100px', marginLeft: '20px', marginBottom: '7px' }} src="/remax-logo.png" alt="GFonts" className="m-0" />
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h1>Web sitemiz üzerinden yorumlayın</h1>
                                            <p>Yorum yapmak için lütfen buraya dokunun.</p>
                                        </div>
                                        <a href="https://remax.com.tr/ofis/detay/elite" className="about-btn" target={'_blank'} rel={'nofollow'}>
                                            <img src="/assets/images/icon-2.svg" alt="Button" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-24">
                        <div className="col-md-6">
                            <div data-aos="zoom-in" className="aos-init aos-animate">
                                <div className="about-profile-box info-box shadow-box h-full">
                                    <div className="inner-profile-icons shadow-box">
                                        <a href={'https://www.instagram.com/remaxelitebingol/'} target={'_blank'} rel={'nofollow'}>
                                            <FiInstagram style={{ width: '20px', height: '20px' }} />
                                        </a>
                                        <a href={'https://www.linkedin.com/in/ismail-%C3%A7if%C3%A7i-0b04451a6/'} target={'_blank'} rel={'nofollow'}>
                                            <FiLinkedin style={{ width: '20px', height: '20px' }} />
                                        </a>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="infos">
                                            <h4>Bizi takip edin</h4>
                                            <h1>Sosyal medya adreslerimiz</h1>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0 aos-init aos-animate" data-aos="zoom-in">
                            <div className="about-client-box info-box shadow-box">
                                <div className="clients d-flex align-items-start gap-24 justify-content-center">
                                    <div className="client-item">
                                        <h1><CountUp end={10} delay={5} duration={6} /></h1>
                                        <p>YILLIK <br />TECRÜBE</p>
                                    </div>
                                    <div className="client-item">
                                        <h1>+<CountUp end={4000} delay={5} separator="." /></h1>
                                        <p>MUTLU <br />MÜŞTERİ</p>
                                    </div>
                                    <div className="client-item">
                                        <h1>+<CountUp end={500} delay={5} duration={3} /></h1>
                                        <p>ALDIĞIMIZ <br />ÖDÜLLER</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {contextHolder}

            <Modal title="MÜŞTERİ MEMNUNİYET FORMU" okText="Gönder"
                cancelText="İptal" centered open={isModalOpen} onOk={submit} onCancel={handleCancel} confirmLoading={confirmLoading}>
                <p className="text-left">
                    Aşağıda, Lisanslı Remax Gayrimenkul Danışmanımızdan aldığınız hizmetten ne derece memnun kaldığınızı ölçümlememiz ve hizmetlerimizi dahada iyileştirmemiz adına bir anketimiz mevcuttur.
                </p>
                <hr />
                <h6>Lütfen İşlem Türünü Seçiniz</h6>
                <p className="justify-content-text">
                    <Checkbox.Group options={transaction_types} onChange={onChange} />
                </p>
                <hr />
                <h6>Lütfen Gayrimenkul Danışmanımızı Puanlayız</h6>
                {questions?.map((question: Question) => {
                    return (
                        <div className="row">
                            <div className="col-12 col-sm-6 pt-1">
                                {question.name}
                            </div>
                            <div className="col-12 col-sm-6">
                                <span className="pl-5">
                                    <Rate
                                        tooltips={score_names}
                                        onChange={(value: number | null) => setQuestionValue(question.id, value)}
                                        value={questionStates[question.id] || null}
                                    />
                                    {questionStates[question.id] ? (
                                        <span className="ant-rate-text">{score_names[questionStates[question.id] - 1]}</span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                            </div>
                        </div>
                    )
                })}
                <hr />
                <div className="row">
                    <div className="col-12">
                        <label>Mesajınız *</label>
                        <TextArea rows={4} className="form-control" onChange={e => setComment(e.target.value)} />
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <label htmlFor="">Adınız *</label>
                        <Input required className="form-control" onChange={e => setFirstName(e.target.value)} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <label htmlFor="">Soyadınız *</label>
                        <Input required className="form-control" onChange={e => setLastName(e.target.value)} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <label htmlFor="">Telefon Numaranız *</label>
                        <div className="input-group">
                            <div className="input-group-text p-0">
                                <Select defaultValue={phone_code_id} className="antd-select-sp" style={{ width: 100 }} showSearch
                                    optionFilterProp="children"
                                    onChange={e => setPhoneCodeId(e)}
                                    filterOption={(input, option) => {
                                        return (
                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                >
                                    {phone_codes?.map((phone_codes: PhoneCode) => {
                                        let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                        return (
                                            <Option value={phone_codes.id} title={phone_codes.name}
                                                key={phone_codes.name}
                                                id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                        )
                                    })}
                                </Select>

                            </div>
                            <PatternFormat format="(###) ### ## ##" required onChange={e => setPhone(e.target.value)} value={phone} className="form-control" allowEmptyFormatting mask=" " />
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <label htmlFor="">Email Adresiniz *</label>
                        <Input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className={agent_status ? 'd-none' : 'col-12 mt-2'}>
                        <label htmlFor="">Gayrimenkul Danışmanı *</label>
                        <Select
                            style={{ height: '38px' }}
                            className="form-control antd-select-sp"
                            showSearch
                            placeholder="Danışman adını yazınız"
                            onChange={e => { setAgentId(e); }}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {users.map((users: User) => {
                                return (
                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                        key={users.first_name + ' ' + users.last_name}
                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="col-12 mt-5">
                        <Checkbox onChange={onPermissionChange} className="pt-2"><strong onClick={() => setKVKKModalOpen(true)}>KVKK metnini okudum onaylıyorum.* </strong></Checkbox>
                    </div>
                    <div className="col-12 mt-2">
                        <Checkbox onChange={onNotificationChange} className="pt-2">RE/MAX ELITE'nın hizmetlerine ilişkin tanıtım amaçlı elektronik iletilere, SMS gönderilerine ve aramalara izin veriyorum.</Checkbox>
                    </div>
                </div>
            </Modal>

            {/* //KVKK modal */}
            <Modal
                title="KİŞİSEL VERİLERİN KORUNMASI KANUNU HAKKINDA BİLGİLENDİRME" 
                okText="Tamam"
                cancelText="Kapat"
                centered
                open={KVKKModalOpen}
                onOk={() => setKVKKModalOpen(false)}
                onCancel={() => setKVKKModalOpen(false)}
            >
                <p>
                    <strong>
                        RE/MAX ELITE olarak güvenliğinize önem veriyoruz ve bu kapsamda başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verilerin korunması amacıyla düzenlenen "Kişisel Verilerin Korunması Kanunu" hakkında sizleri bilgilendirmek istiyoruz.
                    </strong>
                </p>
                <p>
                    6698 Sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) 24 Mart 2016 tarihinde kabul edilmiş olup, yürürlüğe girmiştir. Kanun; Kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile uyacakları usul ve esasları düzenlemek amacıyla kabul edilmiştir. Bu nedenle, bu belgede, <strong>RE/MAX ELITE</strong> adına, Kanun madde 10'dan doğan aydınlatma yükümlülüğünüzü yerine getirmek üzere açıklanmakta ve "Kişisel Verilerin Korunması ve Kişisel Veri Paylaşım izni"; Kullanıcıların/ziyaretçilerin bilgi ve incelemesine sunulmaktadır. Bu sorumluluğumuzun tam idraki ile KVK Kanunu’nda tanımlı şekli ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda izah edildiği surette ve mevzuat tarafından emredilen sınırlar çerçevesinde işlemekteyiz. RE/MAX ELITE olarak, Kişisel Verilerin Korunması Kanunu uyarınca <strong>Veri Sorumlusu sıfatıyla</strong> kişisel verilerinizi ilgili mevzuata uygun olarak alacak, kullanacağız ve işleyeceğiz. <strong>RE/MAX ELITE</strong> tarafından atanacak Veri Sorumlusu temsilcisi de, yasal altyapı sağlandığında Veri Sorumluları Sicilinde ilan edilerek mevzuata uygun şekilde <strong>www.remaxelite.net</strong> uzantılı web adresinde bilahare duyurulacaktır
                </p>
                <p>
                    Kişisel verileriniz, Şirketimiz tarafından sağlanan hizmet ve Şirketimizin ticari faaliyetlerine bağlı olarak değişkenlik gösterebilmekle birlikte; otomatik ya da otomatik olmayan yöntemlerle, Şirketimiz birimleri ve ofisler, Topluluk Şirketleri, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilecektir. Şirketimizin sunduğu ürün ve hizmetlerden yararlandığınız müddetçe oluşturularak ve güncellenerek kişisel verileriniz işlenebilecektir.
                </p>
                <p>
                    Ayrıca, Şirketimizin hizmetlerini kullanmak amacıyla çağrı merkezlerimizi veya internet sayfamızı kullandığınızda, Şirketimizi veya internet sitemizi ziyaret ettiğinizde, Şirketimizin düzenlediği eğitim, seminer veya organizasyonlara katıldığınızda kişisel verileriniz işlenebilecektir.
                </p>
                <p>
                    Toplanan kişisel verileriniz, Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini (Şirketimiz tarafından yürütülen iletişime yönelik idari operasyonlar, Şirkete ait lokasyonların fiziksel güvenliğini ve denetimini sağlamak, Topluluk Şirketleri müşterileri değerlendirme/şikayet yönetimi süreçleri, itibar araştırma süreçleri, etkinlik yönetimi, hukuki uyum süreci, denetim, mali işler v.b.), Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ve Şirketimizin insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla KVK Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.
                </p>
                <p>
                    Toplanan kişisel verileriniz; Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, Şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, Şirketimizin ve Şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini (Şirketimiz tarafından yürütülen iletişime yönelik idari operasyonlar, Şirkete ait lokasyonların fiziksel güvenliğini ve denetimini sağlamak, iş ortağı/müşteri/tedarikçi (yetkili veya çalışanları) değerlendirme süreçleri, itibar araştırma süreçleri, hukuki uyum süreci, denetim, mali işler v.b.), Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ile Şirketimizin insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla iş ortaklarımıza, tedarikçilerimize, Topluluk Şirketlerine, Şirket yetkililerine, hissedarlarımıza, kanunen yetkili kamu kurumları ve özel kişilere, KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.
                </p>
                <p>
                    Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer verilen amaçlar doğrultusunda Şirketçe sunduğumuz ürün ve hizmetlerin belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi gayesi ile edinilir. Kural olarak, kişisel verilerin ilgili kişinin açık rızası olmaksızın işlenmesi mümkün olmamakla birlikte, “ bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması”, “ Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması”, “ Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması”, “ ilgili kişinin kendisi tarafından alenileştirilmiş olması”, “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” ve/veya “Kanunlarda açıkça öngörülmesi” durumlarından herhangi birinin mevcudiyeti halinde, ilgili kişinin açık rızası aranmaksızın kişisel verilerinin işlenmesi mümkün olabilecektir.
                </p>
                <p>
                    İnternet sitemiz, satış ve pazarlama departmanı çalışanlarımız, müşteri ziyaretlerinde toplanan formlar, dijital pazarlama ve çağrı merkezi/internet sitemiz/sosyal medya/organizatörlerimiz/iş ortaklarımız ve bunlarla sınırlı olmamak üzere her türlü kanallar aracılığıyla, kişisel ve/veya özel nitelikli kişisel verileriniz, onayınız dahilinde sözlü, yazılı veya elektronik ortamda toplanabilmektedir. Ayrıca, Genel Müdürlük, perakende satış noktaları, kiosklar, internet şubesi , sosyal medya ve çağrı merkezi gibi kanallar aracılığıyla kişisel verileriniz sözlü, yazılı veya elektronik ortamda toplanabilir.
                </p>
                <p>
                    Kanun hükümleri uyarınca, “Bu Kanunun yayımı tarihinden önce işlenmiş olan kişisel nitelikli kişisel verileriniz, yayımı tarihinden itibaren iki yıl içinde bu Kanun hükümlerine uygun hâle getirilecektir. Buna karşın, Kanunun yayımı tarihinden önce hukuka uygun olarak alınmış rızalar, bir yıl içinde aksine bir irade beyanında bulunulmaması hâlinde, bu Kanuna uygun kabul edilmiş sayılmaktadır”. Belirtiriz ki, RE/MAX ELITE müşterileri, RE/MAX ELITE’ya üye olmakla Kullanım Koşullarını ve Gizlilik Politikası hükümlerini kabul etmiş sayıldığından ve Gizlilik Politikalarımızda, kişisel ve/veya özel nitelikli kişisel verilerinizin teminine ve işlenmesine dair detaylı düzenlemeler olduğundan, Kanun’un yayımı tarihinden önce temin ettiğimiz ve işlenmiş olan kişisel ve/veya özel nitelikli kişisel verilerinize ilişkin olarak, tarafımıza verilen “hukuka uygun rızanız” bulunmaktadır. Kanuna istinaden, bu rızalarınız, bir yıl içinde aksine bir irade beyanında bulunulmaması hâlinde, bu Kanuna uygun kabul edilmiş sayılmaktadır.
                </p>
                <p>
                    <strong>Kanun’un 11. maddesi gereği haklarınız; </strong>Şirketimize başvurarak, kişisel verilerinizin; a) işlenip işlenmediğini öğrenme, b) işlenmişse bilgi talep etme, c) işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme, ç) yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme, d) eksik / yanlış işlenmişse düzeltilmesini isteme, e) KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme, f) aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme, g) münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme, ğ) kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme hakkına sahipsiniz. Bu kapsamdaki haklarınız 07.10.2016 tarihi itibarıyla yürürlüğe girmiştir.
                </p>
            </Modal>

        </Wrapper>
    )
}
export default Index;